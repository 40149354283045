import axios, { AxiosError } from "axios";
import { ResultResponse } from "./models";

export const PostQuiz = async (email: string): Promise<ResultResponse> => {
  try {
    const { data, status } = await axios.post(
      "/api/v0/quiz",
      { username: email }
    );
    return { result: data.quiz, status };
  } catch (error: unknown) {
    return {
      result: {
        _id: "random_uuid",
        questions: [
          {
            answer: "",
            difficulty: "aesy",
            options: ["NAME", "SET", "ALIASES", "AS"],
            question:
              "In SQL, what command allows you to rename a column or table using an alias?",
          },
          {
            answer: "",
            difficulty: "easy",
            options: [
              "Java follows object-oriented programming principles.",
              "Java is an interpreted language.",
              "Java can be used for web development only.",
              "Java cannot run on different platforms.",
            ],
            question: "What is the main benefit of using Java?",
          },
          {
            answer: "",
            difficulty: "easy",
            options: [
              "A variable that holds data.",
              "A function that performs an action.",
              "A blueprint for creating objects.",
              "A condition that controls program flow.",
            ],
            question: "What is a class in Java?",
          },
          {
            answer: "",
            difficulty: "medium",
            options: [
              "A compiler translates code to machine language, while an interpreter executes it directly.",
              "A compiler executes code directly, while an interpreter translates it to machine language.",
              "A compiler and an interpreter both translate code to machine language.",
              "A compiler and an interpreter both execute code directly.",
            ],
            question:
              "What is the difference between a compiler and an interpreter?",
          },
          {
            answer: "",
            difficulty: "medium",
            options: [
              "The ability of one object to impersonate another object.",
              "The ability of an object of one class to be passed as an argument to a method of another class.",
              "The ability of a class to have multiple methods with the same name.",
              "The ability of a subclass to inherit properties and behavior from a superclass.",
            ],
            question: "What is polymorphism in Java?",
          },
        ],
        created_at: Date.now(),
      },
      status: 200,
    };
    // if (error instanceof AxiosError) {
    //     return { result: null, status: error.response?.status ?? 500 }
    // }
    // return { result: null, status: 500 }
  }
};

export const PutQuiz = async ({
  id,
  answers,
}: {
  id: string;
  answers: any[];
}): Promise<ResultResponse> => {
  try {
    const { data, status } = await axios.put(
      `/api/v0/quiz/${id}`,
      { answers }
    );
    console.log(data, status, "PUT QUIZ RESPONSE");
    return { result: { code: data.data.data, message: data.message }, status };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      return { result: null, status: error.response?.status ?? 500 };
    }
    return { result: null, status: 500 };
  }
};
