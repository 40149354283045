import { SignupBody } from "../../services/models";

const initialState: { user: SignupBody } = {
  user: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    preference: "",
    isPrefMail: false,
  },
};

const signupReducer = (
  state: any = initialState,
  { type, payload }: { type: string; payload: any }
) => {
  console.log("SIGNUP REDUCER", type, payload);
  switch (type) {
    case "post/user":
      return { ...state, user: payload };
    default:
      return state;
  }
};

export default signupReducer;
