import { useEffect, useState } from "react";
import TelescopeSvg from '../assets/svg/telescope.svg';
import Q2Svg from '../assets/svg/q2.svg';
import Q3Svg from '../assets/svg/q3.svg';
import Q4Svg from '../assets/svg/q4.svg';
import Q5Svg from '../assets/svg/q5.svg';
import React from "react";
import { useNavigate } from "react-router-dom";
import { PostQuiz, PutQuiz } from "../services/quiz";
import { useDispatch, useSelector } from "react-redux";
import { QuizQuestion } from "../services/models";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import LunchSpinner from "../shared/SpinnerOver";
import bgSuccess from "../assets/svg/success-ending.svg";
import bgFail from "../assets/svg/fail-ending.svg";

import { ReactComponent as Facebook } from '../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../assets/svg/instagram.svg';
import { ReactComponent as Linkedin } from '../assets/svg/linkedin.svg';
import { ReactComponent as Twitter } from '../assets/svg/twitter.svg';
import { ReactComponent as Web } from '../assets/svg/web.svg';

const topImages: any = {
    0: {img: Q2Svg, h: 'h-[118px]'},
    1: {img: Q3Svg, h: 'h-[118px]'},
    2: {img: Q4Svg, h: 'h-[136px]'},
    3: {img: Q5Svg, h: 'h-[120px]'},
    4: {img: Q5Svg, h: 'h-[120px]'},
}

function Quiz() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { quiz } = useSelector((store: any) => store.quizStore);
    const { user } = useSelector((store: any) => store.userStore);

    // PAGE DATA
    const [ isLoading, setIsLoading ] = useState(false);
    const [ qIndex, setQIndex ] = useState(0);

    // API DATA
    const [ questions, setQuestions ] = useState<QuizQuestion[]>([])
    const [ quizId, setQuizId ] = useState('');

    const onStartQuiz = async () => {
        setIsLoading(true);
        console.log("USER DURING START QUIZ", user);
        const { result } = await PostQuiz(user.email);
        if (result) {
            setQuestions(result.questions);
            setQuizId(result.id)
        }
        setIsLoading(false);
    }
    const onSelectAnswer = (value: string) => {
        setQuestions([...questions.slice(0, qIndex), {...questions[qIndex], answer: value}, ...questions.slice(qIndex + 1)]);
    }

    const onNextQuestion = () => {
        if (qIndex === 4) {
            setIsLoading(true);
            PutQuiz({
                id: quizId,
                answers: questions.map(({id, answer}: QuizQuestion) => ({id, answer}))
            }).then(({result, status}) => {
                dispatch({
                    type: 'put/quiz', payload: {
                        success: result.message === 'success',
                        qr: result.code,
                        status,
                    }
                })
                setIsLoading(false);
                return;
            }).catch(({result, status}) => {
                console.log('loggin status in error', status)
                dispatch({
                    type: 'put/quiz', payload: {
                        success: false,
                        qr: '',
                        status
                    }
                })
                setIsLoading(false);
                return;
            }).finally(() => {
            })
        } else {
            setQIndex(qIndex + 1);
        }
    }
    useEffect(() => {
        console.log(user)
        if (!user.firstName) {
            navigate('/');
        }
    }, [])

    console.log(quiz);

    return <div>
        {quiz.status === 0
            ? <div>
                {questions.length === 0 && <div className=" m-auto relative overscroll-none flex flex-col justify-evenly">
                  <div className="mt-14 m-auto w-fit h-[221px] -mx-8 md:mx-auto scale-110">
                    <img src={TelescopeSvg} alt="telescope"/>
                  </div>
                  <div className="mt-10 text-3xl text-[#FFCB2A] font-bold mb-7">
                    Cominciamo
                  </div>
                  <div className="text-lg text-white font-medium">
                    Il quiz durerà solo pochi minuti e se risponderai correttamente, vincerai un gadget!
                  </div>

                  <div className="flex flex-row space-x-5 mt-11">
                    <button className="w-full bg-[#E4087E] text-white text-sm font-bold py-2.5 rounded-lg" onClick={onStartQuiz}>Daje</button>
                  </div>
                </div>}

                {questions.length && <>
                  <div className="counter-bg w-fit rounded-full z-0 m-auto mt-6">
                    <CountdownCircleTimer key={qIndex} duration={30} trailStrokeWidth={1} trailColor={'#FFFFFF'}
                                          colors={"#E4087E"} isPlaying={true} onComplete={onNextQuestion}
                                          isGrowing={true} rotation={'counterclockwise'} strokeWidth={3} size={52}
                    >
                        {({remainingTime}) => {
                            return <div className="text-xl font-semibold text-[#E4087E] ">{remainingTime % 60}</div>
                        }}
                    </CountdownCircleTimer>
                  </div>
                  <div className={`mt-4 m-auto w-fit -mx-8 sm:mx-auto scale-110 ${topImages[qIndex].h}`}>
                    <img src={topImages[qIndex].img} alt="telescope"/>
                  </div>
                  <div className="mt-6">
                    <div className="text-sm font-bold text-[#FFCB2A] uppercase mb-3"> Domanda {qIndex + 1} di 5</div>
                    <div className="text-white text-lg mb-4">{questions[qIndex].question}</div>
                    <div className="space-y-4 flex flex-col mb-4">
                        {questions[qIndex].options.map((option) =>
                            <button className={option === questions[qIndex].answer ? 'answer selected-answer' : 'answer'} onClick={() => onSelectAnswer(option)}>
                                {option}
                            </button>)}
                      <button className="w-full bg-[#E4087E] text-white text-sm font-bold py-2.5 rounded-lg disabled:bg-[#DBDBDB] disabled:cursor-not-allowed"
                              onClick={onNextQuestion}
                              disabled={isLoading || !questions[qIndex].answer}>
                          {qIndex === 4 ? 'Termina il quiz' : 'Avanti'}
                      </button>
                    </div>
                  </div>
                </>}
                <LunchSpinner isOpen={isLoading}/>
            </div>
            : <div className=" m-auto relative overscroll-none">
                <div className="mt-20 text-3xl text-white font-bold mb-7">
                    {!quiz.success
                        ? <>
                            <p>Ci dispiace!</p><p>Hai perso!</p>
                        </> : <>
                            <p>Complimenti!</p><p>Hai vinto!</p>
                        </>}
                </div>

                <div className={`mt-4 m-auto w-fit -mx-8 sm:mx-auto scale-110 h-[222px]`}>
                    <img src={quiz.success ? bgSuccess : bgFail} alt="telescope"/>
                </div>

                {quiz.success
                    ? <div className="text-sm text-white font-medium mt-9">
                        <p>Recati al nostro desk, mostra il QR code e ritira il gadget!</p>
                    </div>
                    : <div className="text-sm text-white font-medium mt-9">
                        <p>Ma restiamo in contatto!</p>
                        <p>Puoi dare un'occhiata al nostro sito oppure seguirci sui social.</p>
                    </div>}

                {!quiz.success && <div className="flex flex-row space-x-5 mt-8 mb-4">
                  <a href="https://www.laserromae.it/" target='_blank'><Web /></a>
                  <a href="https://www.facebook.com/LaserRomae/" target='_blank'><Facebook/></a>
                  <a href="https://twitter.com/LaserRomae" target='_blank'><Twitter/></a>
                  <a href="https://www.linkedin.com/company/laserromae/" target='_blank'><Linkedin/></a>
                  <a href="https://instagram.com/laserromae?igshid=MzRlODBiNWFlZA==" target='_blank'><Instagram/></a>
                </div>}

                {quiz.success && <div className="my-6 w-40 m-auto">
                  <img src={'data:image/png;base64, ' + quiz.qr} alt={'qr-success'}/>
                </div>}

            </div>}
    </div>
}

export default Quiz;