import { Dialog } from '@headlessui/react'
import './shared.css'

export default function LunchSpinner({isOpen}: {isOpen: boolean}) {
    return (
        <Dialog open={isOpen} onClose={() => ''} className="relative z-50">
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

            {/* Full-screen container to center the panel */}
            <div className="fixed inset-0 flex items-center justify-center p-4">
                {/* The actual dialog panel  */}
                <Dialog.Panel className="mx-auto max-w-sm rounded">
                    <div className="lds-hourglass"></div>
                </Dialog.Panel>
            </div>
        </Dialog>
    )
}
