import axios, { AxiosError } from "axios";
import { SignupBody, ResultResponse } from "./models";

export const SingupService = async ({
  firstName,
  lastName,
  email,
  phone,
  preference,
  isPrefMail,
}: SignupBody): Promise<ResultResponse> => {
  try {
    const { data, status } = await axios.post(
      "/api/v0/users",
      {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
        preference,
        is_pref_email: isPrefMail,
      }
    );
    const responseData = {
      id: data.data.InsertedID,
      firstName,
      lastName,
      email,
      phone,
      preference,
      isPrefMail,
    };
    return { result: responseData, status };
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      return { result: null, status: error.response?.status ?? 500 };
    }
    return { result: null, status: 500 };
  }
};

export const DebraSignupService = async ({username, password}: {username: string, password: string}) => {
    try {
        const { data, status } = await axios.post('/api/v0/users/signin', {username, password});
        return { result: data.result, status }
    } catch (error: any) {
        console.error(error);
        return { result: null, statusL: error.response?.status }
    }
}

export const DebraValidate = async ({id}: {id: string}) => {
    try {
        const { data, status } = await axios.get(`/api/v0/quiz/results/${id}`, { withCredentials: true });
        return { result: data.result, status };
    } catch (error: any) {
        console.error(error)
        return { result: null, status: error.response?.status ?? 500 };
    }
}