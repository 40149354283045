import React from 'react';
import './App.css';
import {
    createBrowserRouter,
    RouterProvider,
    HashRouter, createHashRouter
} from "react-router-dom";

import routes from './routes'
import store from './redux'
import {Provider} from "react-redux";
import axios from 'axios';
const router = createHashRouter(routes);

axios.interceptors.request.use(
    (config) => {
        config.withCredentials = true;
        return config
    }
)

function App() {
  return (
      <Provider store={store}>
          <RouterProvider router={router} />
      </Provider>
  );
}

export default App;
