import { combineReducers } from "@reduxjs/toolkit";
import quizReducer from './quiz'
import signupReducer from "./signup";

const rootReducer = combineReducers({
    quizStore: quizReducer,
    userStore: signupReducer
});

export default rootReducer;

