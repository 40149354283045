import {useState, ChangeEvent, useEffect, useMemo} from "react";
import { CheckboxInput, RadioInput, SelectInput, TextInput } from "../common/Input";
import laserLogo from '../assets/images/laser_logo.png';
import { SingupService } from "../services/signup";
import rocket from '../assets/images/rocket.png';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LunchSpinner from "../shared/SpinnerOver";

const SignUp = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ isLoading, setLoading ] = useState(false);

    const [ firstName, setFirstName ] = useState('');
    const [ lastName, setLastName ] = useState('');
    const [ category, setCategory ] = useState('');
    const [ addressMethod, setAddressMethod ] = useState('email');
    const [ contact, setContact ] = useState('');
    const [ policy, setPolicy ] = useState(false);
    const [ sent, setSent ] = useState(false);
    const [ contactValidation, setContactValidation ] = useState<boolean | null>(null);

    const categoryOptions = useMemo(() => [
        'Cyber security',
        'System administrator',
        'Network engineer',
        'Machine learning/ai',
        'Fsociety member',
        'Backend developer',
        'Frontend developer',
        'Mobile developer',
        'UX/UI',
        'DevOps',
        'Project manager',
        'Data analyst',
        'Tester',
        'Eeehh... Excel?',
    ], [])

    const onChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value);
    const onChangeLastName = (e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value);
    const onChangeCategory = (e: string) => setCategory(e);
    const onChangeContact = (e: ChangeEvent<HTMLInputElement>) => validateAndSet(e.target.value);
    const validateAndSet = (value: string): void => {
        switch (addressMethod) {
            case 'email':
                setContactValidation(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)$/.test(value));
                break;
            case 'phone':
                setContactValidation(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value.replace(/\s/g, '')));
                break;
            default:
                break;
        }
        setContact(value);
    }
    const onTogglePolicy = () => setPolicy(!policy);

    const onSendContacts = async () => {
        setLoading(true);
        const {result, status} = await SingupService({
            firstName,
            lastName,
            email: contact,
            phone: contact,
            preference: category,
            isPrefMail: addressMethod === 'email'
        });
        if (status !== 201) {
            console.error('sign up failed')
        } else {
            dispatch({
                type: 'post/user', payload: {
                    firstName,
                    lastName,
                    email: contact,
                    phone: contact,
                    preference: category,
                    isPrefMail: addressMethod === 'email'
                }
            })
            window.scrollTo(0, 0)
            setSent(true);
        }
        setLoading(false);
    }

    const onStartQuiz = () => {
        navigate('/quiz');
    }
    const onClose = () => {
        navigate('/ending');
    }

    useEffect(() => {
        validateAndSet(contact)
    }, [addressMethod])

    return <div className="min-h-screen h-max">
        {sent
            ? <div className="bg-primary pt-[5.625rem] min-h-screen h-max">
                <div className="h-[29.75rem] bg-white m-auto mx-8 rounded-3xl px-11">
                    <div className="relative">
                        <img className="m-auto" src={rocket} alt={'rocket'}/>
                        <div className="w-fit text-3xl leading-10 text-[#E4087E] font-bold -bottom-2 absolute ml-auto mr-auto right-0 left-0">Grazie</div>
                    </div>
                    <div className="text-center">
                        <span className="block mt-4 text-sm font-medium">Form inviato con successo!</span>
                        <span className="block mt-4 font-semibold text-lg">Vuoi partecipare al quiz per vincere un gadget?</span>
                    </div>
                    <div className="space-y-3 mt-5">
                        <button className="btn" onClick={onStartQuiz}>Ci provo</button>
                        <button className="btn btn-outline" onClick={onClose}>Chiudi</button>
                    </div>
                </div>
            </div>
            : <div className="relative h-max min-h-screen">
                <div id="header" className="header">
                    <img src={laserLogo} alt="logo_laserromae"/>
                </div>
                <div className="wrapper">
                    <div className="relative">
                        <div className="absolute right-0 top-0">
                        </div>
                    </div>
                    <div className="my-5">
                        <span className="text-3xl leading-10 text-[#E4087E] font-bold">Ciao 👋</span>
                    </div>
                    <div className="mb-8">
                        <span className="text-lg">Compila il form qui sotto così la nostra azienda ti contatterà!</span>
                    </div>
                    <div className="space-y-2" id="generals">
                        <TextInput value={firstName} onChange={onChangeFirstName} label={'Nome'} placeholder={'Inserisci il tuo nome'}/>
                        <TextInput value={lastName} onChange={onChangeLastName} label={'Cognome'} placeholder={'Inserisci il tuo cognome'}/>
                        <SelectInput value={category} onChange={onChangeCategory} label="Seleziona l'ambito di interesse" options={categoryOptions}/>
                    </div>
                    <div className="mt-8">
                        <p className="text-md font-semibold">Come vuoi essere contattato?</p>
                        <p className="text-xs font-normal text-[#8A8A8A]">Inserisci l'email o il telefono</p>
                        <div id="address-check" className="flex flex-row ">
                            <RadioInput id={'address-method'} className="w-1/2" checked={addressMethod === 'email'} onToggle={() => setAddressMethod('email')} label={'Email'}/>
                            <RadioInput id={'address-method'} checked={addressMethod === 'phone'} onToggle={() => setAddressMethod('phone')} label={'Telefono'}/>
                        </div>
                        <TextInput value={contact} onChange={onChangeContact} placeholder={"Inserisci l'email o il telefono"}
                                   className={!contactValidation ? 'cv-input-invalid' : ''}/>
                        <CheckboxInput id="polity-agreement" checked={policy} onToggle={onTogglePolicy} label={'Acconsento al trattamento dei miei dati personali.'}/>
                    </div>

                    <div className="mt-6 mb-12">
                        <button className="btn" disabled={!firstName || !lastName || !category || !contact || !policy || !contactValidation} onClick={onSendContacts}>
                            Invia
                        </button>
                    </div>

                </div>
                <div className="w-full h-[27px] bg-primary text-center text-[8px] text-white py-2.5 leading-none absolute bottom-0">
                    © Copyrights 2023 Laser Romae s.r.l. Tutti i diritti sono riservati.
                </div>
            </div>
        }
        <LunchSpinner isOpen={isLoading}/>
    </div>
}

export default SignUp
