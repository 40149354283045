import {ReactComponent as Facebook} from '../assets/svg/facebook.svg';
import {ReactComponent as Instagram} from '../assets/svg/instagram.svg';
import {ReactComponent as Linkedin} from '../assets/svg/linkedin.svg';
import {ReactComponent as Twitter} from '../assets/svg/twitter.svg';
import {ReactComponent as Web} from '../assets/svg/web.svg';

import Astronaut from '../assets/images/ending.png'
import React from "react";
const Ending = () => {

    return <div className=" m-auto relative overscroll-none">
        <div className="mt-20 text-3xl text-white font-bold mb-7">
            Restiamo in contatto!
        </div>
        <div className="text-sm text-white font-medium">
            Puoi dare un'occhiata al nostro sito oppure seguirci sui social.
        </div>

        <div className="flex flex-row space-x-5 mt-11">
            <Web />
            <Facebook />
            <Twitter />
            <Linkedin />
            <Instagram />
        </div>

        <div className="absolute -right-[7rem]">
            <img src={Astronaut} alt={'astronaut'} />
        </div>

    </div>
}

export default Ending;