import { useEffect, useState } from "react";
import LunchSpinner from "../shared/SpinnerOver";
import { useParams } from "react-router-dom";
import { DebraValidate } from "../services/signup";

interface UserResponse {
    id?: string
    email?: string
    first_name?: string
    last_name?: string
    phone?: string
    preference?: string
    is_pref_email?: string
}

const DebraValidator = () => {

    const { id } = useParams();

    const [ isLoading, setLoading ] = useState(false);
    const [ result, setResult ] = useState(0);
    const [ user, setUser ] = useState<UserResponse | null>({});

    const getQrCode = async () => {
        if (!id) {
            return
        }
        const { result, status } : { result: {quiz: any, user: UserResponse} | null, status: number } = await DebraValidate({ id });
        console.log(result, status);
        setResult(status);
        if (result) {
            setUser(result!.user);
        }
    }

    const getTitle = () => {
        switch (result) {
            case 200:
                return "Complimenti, il tipo ha vinto. Fagli un sorriso."
            case 208:
                return "Fagli un sorriso, ma questo già l'ha preso il cubo"
            case 404:
                return "Questo si è inventato un QR"
            case 406:
                return "Questo vuole fare il furbo, non ha vinto davvero"
            case 401:
            case 511:
                return "Vai allo stand a prendere la tua ricompensa!"
            default:
                return
        }
    }

    useEffect(() => {
        getQrCode();
    }, [])

    return <div className="p-8 lg:w-[800px] mt-16 bg-white shadow-lg rounded-lg">


        {result !== 401 && result !== 511 && !!user && <div className=" p-4">
          <div className="text-2xl font-semibold">Dati utente:</div>
          <div className="p-3 space-y-4 text-base">
            <div>
              <span className="text-sm">Nome: </span><span>{user.first_name}</span>
            </div>
            <div>
              <span className="text-sm">Cognome: </span><span>{user.last_name}</span>
            </div>
            <div>
              <span className="text-sm">Contatto: </span><span>{user.email ?? user.phone}</span>
            </div>

          </div>
        </div>}

        <div className="p-4 text-xl font-semibold">
            {getTitle()}
        </div>

        <LunchSpinner isOpen={isLoading} />
    </div>
}

export default DebraValidator;