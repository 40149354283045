import { TextInput} from "../common/Input";
import { ChangeEvent, useEffect, useState } from "react";
import { DebraSignupService } from "../services/signup";
import laserLogo from "../assets/images/laser_logo.png";
import { useNavigate } from "react-router-dom";


const DebraLogin = () => {

    const [ username, setUsername ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ fail, setFail ] = useState(false);
    const [ isLoading, setLoading ] = useState(false);
    const [ authenticated, setAuthenticated ] = useState(false);
    const navigate = useNavigate();

    const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => setUsername(e.target.value);
    const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const onSubmit = async (e: any) => {
        e.preventDefault();

        setLoading(true);
        setFail(false);

        const { result, status } = await DebraSignupService({username, password});
        if (status !== 200) {
            setFail(true);
        } else {
            setAuthenticated(true);
        }

        setLoading(false);
    }


    return <div className="">

        {<div className="relative h-max min-h-screen">
            <div id="header" className="header">
                <img src={laserLogo} alt="logo_laserromae"/>
            </div>
            <div className="py-4 font-semibold text-xl px-8">
                Debra authentication
            </div>

            {authenticated
                ? <div className="m-auto flex justify-center items-center">
                    <div>Ti sei authenticata, cya</div>
                </div>
                : <form onSubmit={onSubmit} className="px-8">
                    {fail && <div className="p-3 bg-red-300 rounded-md text-red-600 mb-3">Sbagliato!</div>}
                    <TextInput value={username} onChange={onChangeUsername} placeholder="username"/>
                    <TextInput value={password} type='password' onChange={onChangePassword} placeholder="password"/>

                    <div className="lg:w-1/2 m-auto mt-4">
                        <button type="submit" onClick={onSubmit} className="btn">Log in</button>
                    </div>
                </form>}
            <div className="w-full h-[27px] bg-primary text-center text-[8px] text-white py-2.5 leading-none absolute bottom-0">
                © Copyrights 2023 Laser Romae s.r.l. Tutti i diritti sono riservati.
            </div>
        </div>}



    </div>
}

export default DebraLogin;