import { RouteObject } from "react-router-dom";
import SignUp from "./views/SignUp";
import QuizLayout from "./views/QuizLayout";
import Ending from "./views/Ending";
import Quiz from "./views/Quiz";
import QuizEnding from "./views/QuizEnding";
import DebraLogin from "./views/DebraLogin";
import DebraValidator from "./views/DebraValidator";

const routes: RouteObject[] = [
    {path: "/", element: <SignUp /> },
    {path: '/ending', element: <QuizLayout><Ending /></QuizLayout>},
    {path: '/quiz', element: <QuizLayout><Quiz /></QuizLayout>},
    {path: '/quiz/ending', element: <QuizLayout><QuizEnding /></QuizLayout>},
    {path: '/hr', element: <DebraLogin />},
    {path: '/verify/:id', element: <QuizLayout><DebraValidator /></QuizLayout>},
]

export default routes;