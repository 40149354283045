import { ReactComponent as Facebook } from '../assets/svg/facebook.svg';
import { ReactComponent as Instagram } from '../assets/svg/instagram.svg';
import { ReactComponent as Linkedin } from '../assets/svg/linkedin.svg';
import { ReactComponent as Twitter } from '../assets/svg/twitter.svg';
import { ReactComponent as Web } from '../assets/svg/web.svg';

import { useSelector } from "react-redux";
import React from "react";

import bgSuccess from '../assets/svg/success-ending.svg'
import bgFail from '../assets/svg/fail-ending.svg'

const QuizEnding = () => {

    const { quiz } = useSelector((store: any) => store.quizStore);

    return <div className=" m-auto relative overscroll-none">
        <div className="mt-20 text-3xl text-white font-bold mb-7">
            {!quiz.success
                ? <>
                    <p>Ci dispiace!</p><p>Hai perso!</p>
                </> : <>
                    <p>Complimenti!</p><p>Hai vinto!</p>
                </>}
        </div>

        <div className={`mt-4 m-auto w-fit -mx-8 sm:mx-auto scale-110 h-[222px]`}>
            <img src={quiz.success ? bgSuccess : bgFail} alt="telescope"/>
        </div>

        {quiz.success
            ? <div className="text-sm text-white font-medium mt-9">
                <p>Recati al nostro desk, mostra il QR code e ritira il gadget!</p>
            </div>
            : <div className="text-sm text-white font-medium mt-9">
                <p>Ma restiamo in contatto!</p>
                <p>Puoi dare un'occhiata al nostro sito oppure seguirci sui social.</p>
            </div>}

        {!quiz.success && <div className="flex flex-row space-x-5 mt-8 mb-4">
          <a href="https://www.laserromae.it/" target='_blank'><Web /></a>
          <a href="https://www.facebook.com/LaserRomae/" target='_blank'><Facebook/></a>
          <a href="https://twitter.com/LaserRomae" target='_blank'><Twitter/></a>
          <a href="https://www.linkedin.com/company/laserromae/" target='_blank'><Linkedin/></a>
          <a href="https://instagram.com/laserromae?igshid=MzRlODBiNWFlZA==" target='_blank'><Instagram/></a>
        </div>}

        {quiz.success && <div className="my-6 w-40 m-auto">
          <img src={'data:image/png;base64, ' + quiz.qr} alt={'qr-success'}/>
        </div>}

    </div>
}

export default QuizEnding;