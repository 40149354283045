import {ReactElement} from "react";
import logo from "../assets/images/laser_logo_white.png";

interface QuizLayoutProps {
    children: ReactElement,
}
const QuizLayout = ({children}: QuizLayoutProps) => {

    return <div className="wrapper-quiz">
        <div id="header" className="header-quiz w-fit">
            <img src={logo} alt="laser_logo"/>
        </div>
        <div className="lg:w-[800px] m-auto">
            {children}
        </div>
        <div className="text-white absolute bottom-4 text-[8px]">
            <div>© Copyrights 2023 Laser Romae s.r.l. Tutti i diritti sono riservati.</div>
        </div>
    </div>
}

export default QuizLayout;